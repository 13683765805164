
.dx-widget, .dx-widget input, .dx-widget textarea {
  font-family: Spoqa Han Sans Neo, DXIcons, sans-serif;
}

/* popover */
.dx-popup-wrapper > .dx-overlay-content {
  border-radius: 8px;
  border: 1px solid #D2D1D0;
  overflow: hidden;
  box-shadow: none;
}

.dx-popup-content {
  padding: 0;
}

.dx-popup-content > div > * {
  display: block;
  width: 100%;
  padding: 8px 10px;
  font-size: 14px;
  color: #666666;
  text-align: left;
}

.dx-popup-content > div > *:hover {
  color: #212121;
  background-color: #F1F3F6;
}

.account-popover .dx-popup-content > div > * {
  padding: 14px 20px;
  color: #8CA0AE;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.account-popover .dx-popup-content > div > *:first-child {
  color: #666666;
  font-weight: bold;
  padding: 18px 20px;
  border-bottom: 1px solid #E8E8E8;
}

.account-popover .dx-popup-content > div > *:first-child:hover {
  color: #666666;
  background-color: #FFFFFF;
}

.account-popover .dx-popup-content > div > * > div {
  width: 24px;
  height: 24px;
  background-image: url(./account.png);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 8px;
}

.account-popover .dx-popup-content > div > *:last-child > div {
  background-image: url(./logout.png);
}

.search-popover .dx-popup-content > div > *:first-child {
  background-color: #FFFFFF;
  width: 185px;
}

.search-popover .dx-popup-content > div > *:first-child > div {
  height: 35px;
}

.search-popover.paging .dx-popup-content > div > *:first-child {
  width: 220px;
}

.search-popover.paging .dx-popup-content > div > *:last-child {
  margin-top: 10px;
}

.search-popover.paging .dx-popup-content > div > *:last-child:hover {
  background-color: #FFFFFF;
}

.search-popover.paging .dx-popup-content > div > *:last-child .pagination {
  justify-content: center;
}

.search-popover.paging .dx-popup-content > div > *:last-child .pagination a {
  width: 30px;
  height: 30px;
  line-height: 28px;
}

/* datagrid */
.dx-datagrid {
  background-color: transparent;
}

.dx-datagrid .dx-overlay-content {
  display: none;
}

.dx-datagrid-headers, .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border: none;
}

.dx-header-row > td > .dx-datagrid-text-content {
  vertical-align: middle;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
}

.dx-datagrid .dx-datagrid-table .dx-header-row > td {
  padding: 9px 7px;
}

.dx-header-row td {
  background-color: #F4F6F8;
}

.dx-datagrid .dx-row > tr > td, .dx-datagrid .dx-data-row > td {
  height: 50px;
  padding: 0 7px;
  line-height: normal;
}

.dx-datagrid-rowsview .dx-row {
  border-bottom: 1px solid #F0F0F0;
}

.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {
  vertical-align: middle;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle;
  text-align: center;
}

.dx-header-row td div, .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort-indicator, .dx-datagrid-headers .dx-datagrid-table .dx-row>td:hover .dx-datagrid-text-content {
  color: #8CA0AE;
}

.dx-header-row td:first-child {
  border-radius: 8px 0 0 8px;
}

.dx-header-row td:last-child {
  border-radius: 0 8px 8px 0;
}

.customize .dx-datagrid-table .dx-header-row:first-child td:last-child {
  border-radius: 0 8px 0 0;
}

.customize .dx-datagrid-table .dx-header-row:last-child td:last-child {
  border-radius: 0 0 8px 0;
}

.customize .dx-datagrid-table .dx-header-row:last-child td:first-child {
  border-radius: 0;
}

.customize .dx-datagrid-table .dx-header-row:last-child td {
  border-top: 1px solid #e0e0e0;
}

.dx-datagrid .dx-column-indicators, .dx-datagrid .dx-sort-up, .dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort {
  height: 26px;
}

.dx-datagrid-table .dx-row > tr:hover {
  background-color: #F9F9F9;
}

.dx-datagrid-table .dx-row > tr.active {
  background-color: #F9F9F9;
}

.dx-datagrid-text-content, .dx-datagrid-table td {
  font-size: 13px;
}

.dx-datagrid .dx-sort-up::before {
  content: '';
  display: block;
  width: 15px;
  height: 24px;
  background-image: url(./sort.png);
  background-size: 5px 4px;
  background-position: center center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

.dx-datagrid .dx-sort-down::before {
  content: '';
  display: block;
  width: 15px;
  height: 24px;
  background-image: url(./sort.png);
  background-size: 5px 4px;
  background-position: center center;
  background-repeat: no-repeat;
}

/* datebox */
.dx-texteditor.dx-editor-filled {
  background-color: transparent;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
}

.dx-texteditor.dx-editor-filled::after {
  display: none;
}

.dx-texteditor-input {
  font-size: 14px;
}

.dx-texteditor.dx-editor-filled .dx-texteditor-input {
  padding: 11px 16px 10px;
}

.dx-datebox-date .dx-dropdowneditor-icon::before, .dx-datebox-date.dx-dropdowneditor-active .dx-dropdowneditor-icon::before {
  content: '';
  height: 24px;
  background-image: url(./calendar.png);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}