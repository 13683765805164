@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: bold;
  src: url('./fonts/SpoqaHanSansNeo-Bold.otf') format('woff2'),
    url('./fonts/SpoqaHanSansNeo-Bold.otf') format('woff'),
    url('./fonts/SpoqaHanSansNeo-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: normal;
  src: url('./fonts/SpoqaHanSansNeo-Regular.otf') format('woff2'),
    url('./fonts/SpoqaHanSansNeo-Regular.otf') format('woff'),
    url('./fonts/SpoqaHanSansNeo-Regular.otf') format('truetype');
}